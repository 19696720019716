.geocodeItem:hover {
    color: #6e37ff;
    background: #e2cef3
  }


.geocodeItem {
    cursor:pointer;
    font-family: 'Lato'; 
}

