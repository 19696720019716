@import url("https://fonts.googleapis.com/css?family=Lato");
.App {
  font-family: "Lato", sans-serif;
  text-align: center;
}

@media (max-width: 800px) {
  .desktop {
    display: none;
  }
}

@media (min-width: 800px) {
  .mobile {
    display: none;
    text-align: center;
  }
}

.jazzyBackground {
  /* background-image: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), url('./images/sharon-mccutcheon-663633-unsplash-comp-small.jpg'); */
  background-size: cover;
  height:100%;
  background: linear-gradient(to right, rgb(110, 55, 255), rgb(182, 156, 248));
/* /0062E6, #33AEFF); */
} 

.back {
  height: 100%;
  width: 100%;
  position: fixed;
}