
@media (max-width:800px) {
    .heroContainer {
        height:300px;
    }
}
@media (min-width:800px) {
    .heroContainer {
        height:500px;
    }
}


.landingPageBackgroundImage {
    /* background-image: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), url('../../../images/festival-small.jpg');
    background-size: cover;
    background-repeat: no-repeat; */ 
    /* width: 100%; */
    /* background: linear-gradient(to bottom, #6303B1, #6303B1); */
    height: 100%;
    /* clip-path: polygon(100% 80%, 0% 100%, 0% 0%, 100% 0%); */
}

.datePoll {
    border: 4px solid #6E37FF;
    box-sizing: border-box;
    border-radius: 10px;
}


.datePoll2 {
    border: 4px solid #C20041;
    box-sizing: border-box;
    border-radius: 10px;
}

.dp_heading {
    font-style: normal;
    font-weight: 600;
    /* font-size: 25px; */
    line-height: 30px;
    letter-spacing: 0.02em;
    color: white;
    /* color: #4CDC4C; */
}
.dp_heading3 {
    font-style: normal;
    font-weight: 600;
    /* font-size: 25px; */
    line-height: 30px;
    letter-spacing: 0.02em;
    color: white;
}

.dp_heading2 {
    /* font-family: Lato; */
    /* font-style: normal; */
    font-weight: 600;
    /* font-size: 25px; */
    line-height: 30px;
    letter-spacing: 0.02em;
    color: white;
}

@media (max-width:629px) {
    #pollImage {
        display: none;
    }
    #eventImage {
        display: none;
    }
    .contentDetails {
        display: none;
    }
}





.dp_miniguide_list_text {
    font-family: Lato;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: #000000;
}

.dp_signInExtras_title {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #000000;
}

.dp_signInExtras_listItem {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #000000;
}