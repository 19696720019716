
.forwardButton {
    cursor: pointer
}

.forwardButton:hover {
    background: #22aa22
}

.backButton {
    cursor: pointer
}

.backButton:hover {
    background: #474d52
}

.homeButton {
    cursor: pointer
}

.homeButton:hover {
    background: #d5aa2a
}