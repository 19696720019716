
  ul {
    list-style-type: none;
  }
  body {
    font-family: Verdana, sans-serif;
  }
  
  /* Month header */
  .month {
    padding: 40px 25px;
    background: rgb(110, 55, 255);
    text-align: center;
  }
  
  /* Month list */
  .month ul {
    margin: 0;
    padding: 0;
  }
  
  .month ul li {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
  
  /* Previous button inside month header */
  .month .prev {
    float: left;
    padding-top: 10px;
  }
  
  /* Next button */
  .month .next {
    float: right;
    padding-top: 10px;
  }
  
  /* Weekdays (Mon-Sun) */
  .weekdays {
    margin: 0;
    padding: 10px 0;
    background-color: #ddd;
  }
  
  .weekdays li {
    display: inline-block;
    width: 13.6%;
    color: #666;
    text-align: center;
  }
  
  /* Days (1-31) */
  .days {
    padding: 10px 0;
    background: #eee;
    margin: 0;
  }
  
  .days li {
    list-style-type: none;
    display: inline-block;
    width: 13.6%;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 12px;
    color: #777;
    padding-top: 10px;
    padding-bottom: 10px;
   
  }
  .days li:hover {
    color: #ad58f7;
    background: #e2cef3
  }
  
  /* Highlight the "current" day */
  .days li .active {
    padding: 5px;
    background: #6e37ff;
    color: white !important;
  }

.selected {
  background: #6e37ff;
  color: white !important;
  cursor: pointer;
}

.selected:hover {
  color: #ad58f7;
  background: #e2cef3
}