
.deletePollButton {
    cursor:pointer
}

.deletePollButton:hover {
    background: darkred
}


.deletePollBackButton {
    cursor: pointer
}

.deletePollBackButton:hover {
    background: #474d52
}

.startNewPollButton {
    cursor:pointer
}

.startNewPollButton:hover {
    background: green
}

.startNewEventButton {
    cursor:pointer
}

.startNewEventButton:hover {
    background: lightpurple
}



.viewPollButton {
    cursor:pointer
}

.viewPollButton:hover {
    background: green
}

